import { useQuery } from '@apollo/client';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Switch,
  message
} from 'antd';
import { useForm, useWatch } from 'antd/lib/form/Form';
import { filter, get, includes, keys, nth, toString, trim, uniq } from 'lodash';
import moment from 'moment';
import momentTz from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Calendar, ForwardArrow, Globe } from '../../../assets/svg';
import {
  ACCESSTYPE_KEYS,
  ACCESS_LIMIT,
  ADDONS,
  MOBILE_NUMBER_NOTE,
  ORGANIZATION_TYPES,
  REGEX,
  ROUTES,
  defaultDateFormat
} from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import Portal from '../../../components/Portal';
import { GET_PLANS } from '../graphql/Queries';

const timeZones = momentTz.tz.names();
const timezone = uniq(timeZones)?.map((timeZone, index) => ({
  key: index,
  value: timeZone,
  label: `${timeZone} (GMT${momentTz?.tz(timeZone)?.format('Z')})`
}));

const OrganizationForm = ({ mutation, organizationData, id, mutating }) => {
  const history = useHistory();
  const [form] = useForm();
  const isMultilevelApproval = useWatch('multilevelApproval', form);
  const maxApprovalLevelCount = useWatch('maxApprovalLevel', form);
  const inspectionAssetLimitCount = useWatch('inspectionAssetLimit', form);
  const instructionAssetLimitCount = useWatch('instructionAssetLimit', form);
  const stageMaxLimitCount = useWatch(['featureConfig', 'stageMaxLimit'], form);
  const { pathname } = useLocation();
  const { data } = useQuery(GET_PLANS, { fetchPolicy: 'network-only' });
  const fetchData = nth(organizationData, 0);
  const [accessType, setAccessType] = useState(
    fetchData?.access || [
      ACCESSTYPE_KEYS?.INSPECTION,
      ACCESSTYPE_KEYS?.INSTRUCTION
    ]
  );

  useEffect(() => {
    form.setFieldsValue({
      inspectionAssetLimit: String(fetchData?.inspectionAssetLimit || 5),
      instructionAssetLimit: String(fetchData?.instructionAssetLimit || 5),
      inspectionAccess: includes(accessType, ACCESSTYPE_KEYS?.INSPECTION),
      instructionAccess: includes(accessType, ACCESSTYPE_KEYS?.INSTRUCTION),
      multilevelApproval: fetchData
        ? includes(fetchData?.addOn, ADDONS.MULTI_LEVEL_APPROVAL)
        : true,
      systemBranding: fetchData ? fetchData?.systemBranding : true,
      rfi: fetchData ? includes(fetchData?.addOn, ADDONS.RFI) : false,
      maxApprovalLevel: String(fetchData?.maxApprovalLevel || 1),
      featureConfig: {
        stageMaxLimit: String(fetchData?.featureConfig?.stageMaxLimit || 2),
        issueTag: fetchData?.featureConfig?.issueTag || false,
        issueRecommendation:
          fetchData?.featureConfig?.issueRecommendation || false
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData, accessType]);

  const {
    required,
    email,
    number,
    characterWithoutWhiteSpace
  } = formValidatorRules;
  const navPagination = history?.location?.state?.navPagination;
  const navFilter = history?.location?.state?.navFilter;
  const onFinish = (values) => {
    const {
      organizationName,
      ownerName,
      phoneNumber,
      userLimit,
      adminEmail,
      planId,
      validTill,
      inspection,
      instruction,
      organizationType,
      inspectionAssetLimit,
      instructionAssetLimit,
      multilevelApproval,
      inspectionGalleryAccess,
      instructionAccess,
      inspectionAccess,
      targetActualMetric,
      nomenclature,
      rfi,
      reportConfig,
      featureConfig,
      maxApprovalLevel,
      timeZone,
      systemBranding
    } = values;
    const addOn = [];
    const access = [];
    if (instructionAccess) {
      access?.push(ACCESSTYPE_KEYS?.INSTRUCTION);
    }
    if (inspectionAccess) {
      access?.push(ACCESSTYPE_KEYS?.INSPECTION);
    }
    if (!access?.length > 0) {
      message.destroy();
      return message?.error(
        'Enable at least one access type from addon services'
      );
    }
    if (inspection) {
      addOn?.push(ADDONS.WHATSAPP);
    }
    if (instruction) {
      addOn?.push(ADDONS.INSTRUCTION_WHATSAPP);
    }
    if (multilevelApproval) {
      addOn?.push(ADDONS.MULTI_LEVEL_APPROVAL);
    }
    if (inspectionGalleryAccess) {
      addOn?.push(ADDONS.INSPECTION_GALLERY_ACCESS);
    }
    if (targetActualMetric) {
      addOn?.push(ADDONS.TARGET_ACTUAL_METRIC);
    }
    if (nomenclature) {
      addOn?.push(ADDONS.NOMENCLATURE);
    }
    if (rfi) {
      addOn?.push(ADDONS.RFI);
    }
    if (reportConfig) {
      addOn?.push(ADDONS.REPORT_CONFIG);
    }

    const input = pathname.includes('edit')
      ? {
          planId: Number(planId),
          timeZone,
          organizationName,
          ownerName,
          phoneNumber,
          access,
          validTill,
          userLimit: Number(userLimit),
          addOn,
          organizationType,
          inspectionAssetLimit: Number(inspectionAssetLimit),
          instructionAssetLimit: Number(instructionAssetLimit),
          featureConfig: {
            ...featureConfig,
            stageMaxLimit: Number(featureConfig?.stageMaxLimit)
          },
          maxApprovalLevel: maxApprovalLevel
            ? Number(maxApprovalLevel)
            : undefined,
          systemBranding
        }
      : {
          adminEmail,
          planId: Number(planId),
          organizationName,
          timeZone,
          ownerName,
          phoneNumber,
          access,
          validTill,
          userLimit: Number(userLimit),
          addOn,
          organizationType,
          inspectionAssetLimit: Number(inspectionAssetLimit),
          instructionAssetLimit: Number(instructionAssetLimit),
          featureConfig: {
            ...featureConfig,
            stageMaxLimit: Number(featureConfig?.stageMaxLimit)
          },
          maxApprovalLevel: maxApprovalLevel
            ? Number(maxApprovalLevel)
            : undefined,
          systemBranding
        };

    mutation({
      variables: {
        id: id || null,
        data: input
      }
    })
      .then((response) => {
        const orgData = get(
          response,
          pathname.includes('edit') ? 'data.updateTenant' : 'data.createTenant'
        );
        if (orgData) {
          history.push(ROUTES.ORGANIZATION, {
            navPagination: navPagination
          });
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };

  return (
    <div className="form-content">
      <Portal portalId="header-left-content">
        <h1>
          <div className="d-flex">
            <Globe
              onClick={() =>
                history.push(ROUTES.ORGANIZATION, {
                  navPagination,
                  navFilter
                })
              }
              className="pointer"
            />
            <ForwardArrow />
          </div>
          {pathname.includes('edit') ? 'Edit Organization' : 'Add Organization'}
        </h1>
      </Portal>
      <Portal portalId="header-right-content">
        <div className="form-buttons">
          <Button
            shape="round"
            className="cancel-button"
            onClick={() =>
              history.push(ROUTES.ORGANIZATION, {
                navPagination,
                navFilter
              })
            }
          >
            Cancel
          </Button>
          <Button
            shape="round"
            className="save-button"
            loading={mutating}
            htmlType="submit"
            onClick={() => form.submit()}
          >
            Save
          </Button>
        </div>
      </Portal>
      <div className="organization-form">
        <div className="add-organization-form">
          <div className="add-organization-content">
            <Form
              onFinish={onFinish}
              layout="vertical"
              form={form}
              initialValues={
                organizationData?.length > 0
                  ? {
                      ...fetchData,
                      planId: fetchData?.subscription?.planObj?.id,
                      validTill: moment(
                        `${fetchData?.subscription?.validTill}`
                      ),
                      name: fetchData?.subscription?.planObj?.name,
                      access: fetchData?.access,
                      inspection: includes(fetchData?.addOn, ADDONS.WHATSAPP),
                      instruction: includes(
                        fetchData?.addOn,
                        ADDONS.INSTRUCTION_WHATSAPP
                      ),
                      inspectionGalleryAccess: includes(
                        fetchData?.addOn,
                        ADDONS.INSPECTION_GALLERY_ACCESS
                      ),
                      targetActualMetric: includes(
                        fetchData?.addOn,
                        ADDONS.TARGET_ACTUAL_METRIC
                      ),
                      nomenclature: includes(
                        fetchData?.addOn,
                        ADDONS.NOMENCLATURE
                      ),
                      reportConfig: includes(
                        fetchData?.addOn,
                        ADDONS.REPORT_CONFIG
                      ),
                      userLimit: toString(fetchData?.userLimit)
                    }
                  : ''
              }
            >
              <Row gutter={24}>
                <Col xs={24} lg={8}>
                  <Card className="mb-10">
                    <div className="plan-details">
                      <h3 className="mt-20">Organization Details</h3>
                      <Form.Item
                        name="organizationName"
                        label="Organization Name"
                        rules={[
                          required,
                          characterWithoutWhiteSpace,
                          {
                            max: 100,
                            message: 'should not be more than 100 characters'
                          }
                        ]}
                      >
                        <Input placeholder="Name of Organization" />
                      </Form.Item>
                      <div className="owner-details">
                        <Form.Item
                          name="ownerName"
                          label="System Admin Name"
                          rules={[
                            required,
                            characterWithoutWhiteSpace,
                            {
                              max: 100,
                              message: 'should not be more than 100 characters'
                            }
                          ]}
                        >
                          <Input placeholder="Enter Owner Name" />
                        </Form.Item>
                        <Form.Item
                          name="adminEmail"
                          label="System Admin Email"
                          rules={[
                            required,
                            email,
                            {
                              max: 254,
                              message: 'should not be more than 254 characters'
                            }
                          ]}
                        >
                          <Input
                            placeholder="Enter Email"
                            disabled={pathname.includes('edit')}
                          />
                        </Form.Item>
                        <Form.Item
                          name="phoneNumber"
                          label="System Admin Mobile
                  Number"
                          rules={[
                            {
                              required: true,
                              message: 'This field is required!'
                            },
                            {
                              pattern: REGEX.PHONE,
                              message: 'should be a valid contact number'
                            }
                          ]}
                        >
                          <Input placeholder="Enter Mobile Number" />
                        </Form.Item>
                        <div className="text-danger note-text mb-10 fs-14">
                          Note : {MOBILE_NUMBER_NOTE}
                        </div>
                        <Form.Item
                          name="organizationType"
                          label="Organization Type"
                          rules={[required]}
                        >
                          <Select
                            name="organizationType"
                            placeholder="Select Organization Type"
                            allowClear
                            getPopupContainer={(triggerNode) =>
                              triggerNode?.parentElement
                            }
                          >
                            {keys(ORGANIZATION_TYPES).map((type) => (
                              <Select.Option key={type} value={type}>
                                {ORGANIZATION_TYPES[type]}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name="timeZone"
                          label="TimeZone"
                          rules={[required]}
                        >
                          <Select
                            showSearch
                            placeholder="Select Timezone"
                            getPopupContainer={(triggerNode) =>
                              triggerNode?.parentElement
                            }
                            filterOption={(input, option) => {
                              const label = get(option, 'label', '');
                              return label
                                ?.toLowerCase()
                                ?.includes(trim(input?.toLowerCase()));
                            }}
                            options={timezone}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col xs={24} lg={8}>
                  <Card className="mb-10">
                    <div className="plan-details">
                      <h3 className="mt-20">Plan Details</h3>

                      <Form.Item name="planId" label="Plan" rules={[required]}>
                        <Select
                          name="planId"
                          placeholder="Select a Plan"
                          getPopupContainer={(triggerNode) =>
                            triggerNode?.parentElement
                          }
                        >
                          {data?.planList?.map((plan) => (
                            <Select.Option key={plan?.id}>
                              {plan?.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        name="userLimit"
                        label="Number of users"
                        rules={[
                          required,
                          {
                            pattern: REGEX.NUMBER,
                            message: 'Should be a valid number'
                          },
                          {
                            max: 8,
                            message: 'Maximum number limit exceeded'
                          }
                        ]}
                      >
                        <Input
                          placeholder="Enter Number of Users"
                          className="width-percent-100"
                          maxLength={20}
                        />
                      </Form.Item>

                      <Form.Item
                        name="validTill"
                        label="Valid Till"
                        rules={[required]}
                      >
                        <DatePicker
                          format={defaultDateFormat}
                          suffixIcon={<Calendar />}
                          placeholder="Select Date"
                        />
                      </Form.Item>
                    </div>
                  </Card>
                </Col>
                <Col xs={24} lg={8}>
                  <Card>
                    <h1>Addon Services</h1>
                    <div className="addon-div">
                      <div className="font-weight-500 m-5">1. Access Type</div>
                      <div className="sub-addon-div">
                        <div>a. Inspection</div>
                        <Form.Item
                          name="inspectionAccess"
                          valuePropName="checked"
                        >
                          <Switch
                            onChange={(e) => {
                              if (e) {
                                setAccessType(
                                  uniq([
                                    ...accessType,
                                    ACCESSTYPE_KEYS.INSPECTION
                                  ])
                                );
                              } else {
                                setAccessType(
                                  filter(
                                    accessType,
                                    (record) =>
                                      record !== ACCESSTYPE_KEYS.INSPECTION
                                  )
                                );
                              }
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className="sub-addon-div">
                        <div>b. Instruction</div>
                        <Form.Item
                          name="instructionAccess"
                          valuePropName="checked"
                        >
                          <Switch
                            onChange={(e) => {
                              if (e) {
                                setAccessType(
                                  uniq([
                                    ...accessType,
                                    ACCESSTYPE_KEYS.INSTRUCTION
                                  ])
                                );
                              } else {
                                setAccessType(
                                  filter(
                                    accessType,
                                    (record) =>
                                      record !== ACCESSTYPE_KEYS.INSTRUCTION
                                  )
                                );
                              }
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className="font-weight-500 m-5">2. Whatsapp</div>
                      {includes(accessType, ACCESSTYPE_KEYS.INSPECTION) && (
                        <div className="sub-addon-div">
                          <div>a. Inspection</div>
                          <Form.Item name="inspection" valuePropName="checked">
                            <Switch />
                          </Form.Item>
                        </div>
                      )}
                      {includes(accessType, ACCESSTYPE_KEYS.INSTRUCTION) && (
                        <div className="sub-addon-div">
                          <div>b. Instruction</div>
                          <Form.Item name="instruction" valuePropName="checked">
                            <Switch />
                          </Form.Item>
                        </div>
                      )}
                      <div className="font-weight-500 m-5">3. Photo limit</div>
                      {includes(accessType, ACCESSTYPE_KEYS.INSPECTION) && (
                        <div className="sub-addon-div">
                          <div>a. Inspection</div>
                          <div className="d-flex align-center">
                            <Button
                              shape="circle"
                              className="number-button mr-5"
                              disabled={inspectionAssetLimitCount <= 1}
                              onClick={() => {
                                if (
                                  Number(
                                    form.getFieldValue('inspectionAssetLimit')
                                  ) > 1
                                ) {
                                  form.setFieldsValue({
                                    inspectionAssetLimit: String(
                                      Number(
                                        form.getFieldValue(
                                          'inspectionAssetLimit'
                                        )
                                      ) - 1
                                    )
                                  });
                                }
                              }}
                            >
                              -
                            </Button>
                            <Form.Item
                              name="inspectionAssetLimit"
                              className="input-number"
                              rules={[
                                required,
                                number,
                                { max: 9, message: 'Limit exceeded!' },
                                () => ({
                                  validator(_, value) {
                                    if (
                                      Number(value) >
                                      ACCESS_LIMIT.PHOTO_PER_CHECKLIST
                                    ) {
                                      return Promise.reject(
                                        new Error('Limit exceeded')
                                      );
                                    }
                                    return Promise.resolve();
                                  }
                                })
                              ]}
                            >
                              <Input />
                            </Form.Item>
                            <Button
                              shape="circle"
                              className="number-button ml-5"
                              disabled={
                                inspectionAssetLimitCount >=
                                ACCESS_LIMIT.PHOTO_PER_CHECKLIST
                              }
                              onClick={() => {
                                if (
                                  Number(
                                    form.getFieldValue('inspectionAssetLimit')
                                  ) < ACCESS_LIMIT.PHOTO_PER_CHECKLIST
                                ) {
                                  form.setFieldsValue({
                                    inspectionAssetLimit: String(
                                      Number(
                                        form.getFieldValue(
                                          'inspectionAssetLimit'
                                        )
                                      ) + 1
                                    )
                                  });
                                }
                              }}
                            >
                              +
                            </Button>
                          </div>
                        </div>
                      )}
                      {includes(accessType, ACCESSTYPE_KEYS.INSTRUCTION) && (
                        <div className="sub-addon-div">
                          <div>b. Instruction</div>
                          <div className="d-flex align-center">
                            <Button
                              shape="circle"
                              className="number-button mr-5"
                              disabled={instructionAssetLimitCount <= 1}
                              onClick={() => {
                                if (
                                  Number(
                                    form.getFieldValue('instructionAssetLimit')
                                  ) > 1
                                ) {
                                  form.setFieldsValue({
                                    instructionAssetLimit: String(
                                      Number(
                                        form.getFieldValue(
                                          'instructionAssetLimit'
                                        )
                                      ) - 1
                                    )
                                  });
                                }
                              }}
                            >
                              -
                            </Button>
                            <Form.Item
                              name="instructionAssetLimit"
                              className="input-number"
                              rules={[
                                required,
                                number,
                                { max: 9, message: 'Limit exceeded!' },
                                () => ({
                                  validator(_, value) {
                                    if (
                                      Number(value) >
                                      ACCESS_LIMIT.PHOTO_PER_CHECKLIST
                                    ) {
                                      return Promise.reject(
                                        new Error('Limit exceeded')
                                      );
                                    }
                                    return Promise.resolve();
                                  }
                                })
                              ]}
                            >
                              <Input />
                            </Form.Item>
                            <Button
                              shape="circle"
                              className="number-button ml-5"
                              disabled={
                                instructionAssetLimitCount >=
                                ACCESS_LIMIT.PHOTO_PER_CHECKLIST
                              }
                              onClick={() => {
                                if (
                                  Number(
                                    form.getFieldValue('instructionAssetLimit')
                                  ) < ACCESS_LIMIT.PHOTO_PER_CHECKLIST
                                ) {
                                  form.setFieldsValue({
                                    instructionAssetLimit: String(
                                      Number(
                                        form.getFieldValue(
                                          'instructionAssetLimit'
                                        )
                                      ) + 1
                                    )
                                  });
                                }
                              }}
                            >
                              +
                            </Button>
                          </div>
                        </div>
                      )}
                      {includes(accessType, ACCESSTYPE_KEYS.INSPECTION) && (
                        <>
                          <div className="d-flex justify-between mt-5">
                            <div className="font-weight-500 m-5">
                              4. Approver
                            </div>
                            <Form.Item
                              name="multilevelApproval"
                              valuePropName="checked"
                            >
                              <Switch />
                            </Form.Item>
                          </div>
                          {isMultilevelApproval && (
                            <div className="sub-addon-div">
                              <div>a. Approver Level</div>
                              <div className="d-flex align-center">
                                <Button
                                  shape="circle"
                                  className="number-button mr-5"
                                  disabled={maxApprovalLevelCount <= 1}
                                  onClick={() => {
                                    if (
                                      Number(
                                        form.getFieldValue('maxApprovalLevel')
                                      ) > 1
                                    ) {
                                      form.setFieldsValue({
                                        maxApprovalLevel: String(
                                          Number(
                                            form.getFieldValue(
                                              'maxApprovalLevel'
                                            )
                                          ) - 1
                                        )
                                      });
                                    }
                                  }}
                                >
                                  -
                                </Button>
                                <Form.Item
                                  name="maxApprovalLevel"
                                  className="input-number"
                                  rules={[
                                    required,
                                    number,
                                    { max: 9, message: 'Limit exceeded!' },
                                    () => ({
                                      validator(_, value) {
                                        if (
                                          Number(value) >
                                          ACCESS_LIMIT.APPROVAL_LEVEL_PER_CHECKLIST
                                        ) {
                                          return Promise.reject(
                                            new Error('Limit exceeded')
                                          );
                                        }
                                        return Promise.resolve();
                                      }
                                    })
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                                <Button
                                  shape="circle"
                                  disabled={
                                    maxApprovalLevelCount >=
                                    ACCESS_LIMIT.APPROVAL_LEVEL_PER_CHECKLIST
                                  }
                                  className="number-button ml-5"
                                  onClick={() => {
                                    if (
                                      Number(
                                        form.getFieldValue('maxApprovalLevel')
                                      ) <
                                      ACCESS_LIMIT.APPROVAL_LEVEL_PER_CHECKLIST
                                    ) {
                                      form.setFieldsValue({
                                        maxApprovalLevel: String(
                                          Number(
                                            form.getFieldValue(
                                              'maxApprovalLevel'
                                            )
                                          ) + 1
                                        )
                                      });
                                    }
                                  }}
                                >
                                  +
                                </Button>
                              </div>
                            </div>
                          )}
                          <div className="d-flex justify-between mt-5">
                            <div className="font-weight-500 m-5">
                              5. Checklist
                            </div>
                          </div>
                          <div className="sub-addon-div">
                            <div>a. Stage Limit</div>
                            <div className="d-flex align-center">
                              <Button
                                shape="circle"
                                className="number-button mr-5"
                                disabled={stageMaxLimitCount <= 1}
                                onClick={() => {
                                  if (
                                    Number(
                                      form.getFieldValue([
                                        'featureConfig',
                                        'stageMaxLimit'
                                      ])
                                    ) > 1
                                  ) {
                                    form.setFieldsValue({
                                      featureConfig: {
                                        stageMaxLimit: String(
                                          Number(
                                            form.getFieldValue([
                                              'featureConfig',
                                              'stageMaxLimit'
                                            ])
                                          ) - 1
                                        )
                                      }
                                    });
                                  }
                                }}
                              >
                                -
                              </Button>
                              <Form.Item
                                name={['featureConfig', 'stageMaxLimit']}
                                className="input-number"
                                rules={[
                                  required,
                                  number,
                                  { max: 9, message: 'Limit exceeded!' },
                                  () => ({
                                    validator(_, value) {
                                      if (
                                        Number(value) >
                                        ACCESS_LIMIT.STAGES_PER_CHECKLIST
                                      ) {
                                        return Promise.reject(
                                          new Error('Limit exceeded')
                                        );
                                      }
                                      return Promise.resolve();
                                    }
                                  })
                                ]}
                              >
                                <Input />
                              </Form.Item>
                              <Button
                                shape="circle"
                                disabled={
                                  stageMaxLimitCount >=
                                  ACCESS_LIMIT.STAGES_PER_CHECKLIST
                                }
                                className="number-button ml-5"
                                onClick={() => {
                                  if (
                                    Number(
                                      form.getFieldValue([
                                        'featureConfig',
                                        'stageMaxLimit'
                                      ])
                                    ) < ACCESS_LIMIT.STAGES_PER_CHECKLIST
                                  ) {
                                    form.setFieldsValue({
                                      featureConfig: {
                                        stageMaxLimit: String(
                                          Number(
                                            form.getFieldValue([
                                              'featureConfig',
                                              'stageMaxLimit'
                                            ])
                                          ) + 1
                                        )
                                      }
                                    });
                                  }
                                }}
                              >
                                +
                              </Button>
                            </div>
                          </div>
                          <div className="d-flex justify-between mt-5">
                            <div className="font-weight-500 m-5">
                              6. Gallery access for inspection
                            </div>
                            <Form.Item
                              name="inspectionGalleryAccess"
                              valuePropName="checked"
                            >
                              <Switch />
                            </Form.Item>
                          </div>
                          <div className="d-flex justify-between mt-5">
                            <div className="font-weight-500 m-5">7. Target</div>
                            <Form.Item
                              name="targetActualMetric"
                              valuePropName="checked"
                            >
                              <Switch />
                            </Form.Item>
                          </div>
                          <div className="d-flex justify-between mt-5">
                            <div className="font-weight-500 m-5">
                              8. Nomenclature
                            </div>
                            <Form.Item
                              name="nomenclature"
                              valuePropName="checked"
                            >
                              <Switch />
                            </Form.Item>
                          </div>
                        </>
                      )}
                      {includes(accessType, ACCESSTYPE_KEYS.INSTRUCTION) && (
                        <>
                          <div className="d-flex justify-between mt-5">
                            <div className="font-weight-500 m-5">
                              9. Instruction Tags
                            </div>
                            <Form.Item
                              name={['featureConfig', 'issueTag']}
                              valuePropName="checked"
                            >
                              <Switch />
                            </Form.Item>
                          </div>
                          <div className="d-flex justify-between mt-5">
                            <div className="font-weight-500 m-5">
                              10. Instruction Recommendation
                            </div>
                            <Form.Item
                              name={['featureConfig', 'issueRecommendation']}
                              valuePropName="checked"
                            >
                              <Switch />
                            </Form.Item>
                          </div>
                        </>
                      )}
                      {includes(accessType, ACCESSTYPE_KEYS.INSPECTION) && (
                        <div className="d-flex justify-between mt-5">
                          <div className="font-weight-500 m-5">11. RFI</div>
                          <Form.Item name="rfi" valuePropName="checked">
                            <Switch />
                          </Form.Item>
                        </div>
                      )}
                      <div className="d-flex justify-between mt-5">
                        <div className="font-weight-500 m-5">
                          12. Report Configuration
                        </div>
                        <Form.Item name="reportConfig" valuePropName="checked">
                          <Switch />
                        </Form.Item>
                      </div>
                      <div className="d-flex justify-between mt-5">
                        <div className="font-weight-500 m-5">
                          13. Report DigiQC branding
                        </div>
                        <Form.Item
                          name="systemBranding"
                          valuePropName="checked"
                        >
                          <Switch />
                        </Form.Item>
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganizationForm;
